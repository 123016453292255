import { faClose, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'

import React, { Fragment, useRef } from 'react'

const AddOfferModal = ({ show, setShow, setSubmit, offer, applyOffer, dogName, actionType }) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => setShow(false)}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full lg:w-5/6 max-w-lg transform rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all sm:my-8 md:p-8">
              <div>
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={24}
                  className="absolute top-4 right-4 cursor-pointer md:top-8 md:right-8"
                  onClick={() => setShow(false)}
                />
                <div>
                  <p className="my-6 text-center font-sans font-normal text-base lg:text-xl">
                    We love cooking for {dogName ? dogName : 'your dog'}
                  </p>

                  <p className="text-center font-sans font-normal text-base lg:text-xl">
                    Can we tempt you to stay with
                  </p>
                  <p className="mb-2 font-bold text-center font-sans text-base lg:text-xl">{offer}?</p>
                  <div className="flex flex-col justify-center items-center">
                    <button
                      className="btn-cta my-2 w-full lg:w-2/3"
                      onClick={() => {
                        applyOffer(true)
                        setShow(false)
                        setSubmit(true)
                      }}
                    >
                      Apply discount
                    </button>{' '}
                    <button
                      className="btn-primary my-2 w-full lg:w-2/3 flex justify-center items-center"
                      onClick={() => {
                        applyOffer(false)
                        setShow(false)
                        setSubmit(true)
                      }}
                    >
                      {actionType === 'cancel' ? 'Cancel' : 'Pause'} plan
                    </button>
                    <button onClick={() => setShow(false)} className="btn-secondary mt-4 mb-2">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddOfferModal
