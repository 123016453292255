import OtherReasonModal from 'components/ui/modal/OtherReasonModal'
import { Field, Form, Formik } from 'formik'
import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { cancelPauseReason } from 'state/actions/account/accountPlanAction'
import { getPlanCancelReasons, getPlanPauseReasons } from 'state/apis/account/planApi'
import { PlanType } from 'state/types/account/accountPlanType'
import loadingGIF from '../../../../images/loadingspinner.gif'

const Status: React.FC = ({ subscriptionId, actionType }) => {
  const dispatch = useDispatch()
  const { plans } = useSelector((state: RootState) => state.accountPlan)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [cancelReasons, setCancelReasons] = useState([])
  const [pauseReasons, setPauseReasons] = useState([])
  const [loading, setLoading] = useState(false)
  const [showOtherReasonModal, setShowOtherReasonModal] = useState(false)
  const [cancelCategories, setCancelCategories] = useState([])

  const [activePlan, setActivePlan] = useState<PlanType | null>(null)
  console.log(cancelCategories, 'cancel categories')

  const categories = [
    { name: 'food_and_storage', label: 'Food & storage' },
    { name: 'health', label: 'Health' },
    { name: 'service_and_delivery', label: 'Service & delivery' },
    { name: 'other', label: 'Other' }
  ]

  const randomiseReasons = (reasons: any) => {
    for (let i = reasons.length - 1; i > 0; i--) {
      const r = Math.floor(Math.random() * (i + 1))
      ;[reasons[i], reasons[r]] = [reasons[r], reasons[i]]
    }
    return reasons
  }

  const getReasons = async () => {
    if (actionType === 'pause') {
      setLoading(true)
      const pauseReasonsFromApi = await getPlanPauseReasons()
      setLoading(false)
      setPauseReasons(randomiseReasons(pauseReasonsFromApi))
    } else if (actionType === 'cancel') {
      setCancelCategories(randomiseReasons(categories))
      setLoading(true)
      const cancelReasonsFromApi = await getPlanCancelReasons()
      setLoading(false)
      setCancelReasons(randomiseReasons(cancelReasonsFromApi))
    }
  }

  useEffect(() => {
    if ((actionType !== 'pause' && actionType !== 'cancel') || !subscriptionId) {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
    }
  }, [])

  useEffect(() => {
    getReasons()

    if (plans) {
      plans.map(plan => {
        if (plan.subscriptionId === subscriptionId) {
          setActivePlan(plan)
        }
      })
    }
  }, [])

  if (
    loading &&
    ((actionType === 'pause' && !pauseReasons?.length) || (actionType === 'cancel' && !cancelReasons?.length))
  ) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  return (
    <div className="mt-8">
      <div className="mb-2">
        <Link to="/account/plan/">&larr; Go back to plan</Link>
      </div>
      <Formik initialValues={{}} onSubmit={() => {}}>
        {_props => (
          <Form className="mx-auto w-full text-center md:max-w-[80%]">
            <h1 className="mb-8">
              <span className="capitalize">{actionType}</span> plan
            </h1>
            <p className="mb-8">
              Please let us know the reason you are {actionType === 'cancel' ? 'cancelling' : 'pausing'} your dog's plan
            </p>

            {actionType === 'pause' && (
              <div>
                {pauseReasons.map((pr: any) => (
                  <div>
                    <button
                      className="btn-primary mb-4 !w-full !max-w-[630px] justify-center"
                      onClick={async () => {
                        if (pr.id === 12) {
                          setShowOtherReasonModal(true)
                        } else {
                          await dispatch(
                            cancelPauseReason({
                              subscriptionId,
                              activePlan,
                              actionType: actionType,
                              other: '',
                              id: pr.id,
                              reason: pr.reason,
                              incentive: pr.incentive
                            })
                          )
                          navigate(
                            spoofMode
                              ? `/account/plan/${subscriptionId}/status/${actionType}/${pr.incentive.redirect}/?userId=` +
                                  spoofedUserId
                              : `/account/plan/${subscriptionId}/status/${actionType}/${pr.incentive.redirect}`
                          )
                        }
                      }}
                    >
                      {pr.reason}
                    </button>
                  </div>
                ))}
              </div>
            )}

            {actionType === 'cancel' && (
              <div className="mb-12">
                {cancelCategories.map((c: any) => (
                  <>
                    <h2 className="mt-12 mb-4">{c.label}</h2>
                    {cancelReasons
                      .filter((cr: any) => cr.category === c.name)
                      .map((cr: any) => (
                        <div>
                          <button
                            className="btn-primary mb-4 !w-full !max-w-[630px] justify-center"
                            onClick={async () => {
                              if (c.name === 'other') {
                                setShowOtherReasonModal(true)
                              } else {
                                await dispatch(
                                  cancelPauseReason({
                                    subscriptionId,
                                    activePlan,
                                    actionType: actionType,
                                    other: '',
                                    id: cr.id,
                                    reason: cr.reason,
                                    incentive: cr.incentive
                                  })
                                )
                                navigate(
                                  spoofMode
                                    ? `/account/plan/${subscriptionId}/status/${actionType}/${cr.incentive.redirect}/?userId=` +
                                        spoofedUserId
                                    : `/account/plan/${subscriptionId}/status/${actionType}/${cr.incentive.redirect}`
                                )
                              }
                            }}
                          >
                            {cr.reason}
                          </button>
                        </div>
                      ))}
                  </>
                ))}
              </div>
            )}
          </Form>
        )}
      </Formik>
      <div className="mx-auto w-full text-center">
        <Link className="btn-secondary !no-underline" to="/account/plan/">
          Back
        </Link>
      </div>
      {showOtherReasonModal && (
        <OtherReasonModal
          show={showOtherReasonModal}
          setShow={setShowOtherReasonModal}
          actionType={actionType}
          subscriptionId={subscriptionId}
          reason={'Other (please specify)'}
        />
      )}
    </div>
  )
}

export default Status
