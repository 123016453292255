import ErrorModal from 'components/ui/modal/ErrorModal'
import RecipeOrTreatAlterModal from 'components/ui/modal/RecipeOrTreatModalAlter'

import { Field, Formik } from 'formik'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CdnImage from 'components/util/CdnImage'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updatePlanRadio } from 'state/actions/account/accountDogAction'
import { createDogsExistingPlan, resetFlowDogs } from 'state/actions/account/flow/dogAction'
import {
  populateRecipeAndTreatData,
  prefillAddons,
  removeAddons,
  resetFlowPlan
} from 'state/actions/account/flow/planAction'
import {
  createShippingConfirmDogsExistingAddons,
  resetChangedShipping
} from 'state/actions/account/flow/shippingAction'
import { addAddons } from 'state/actions/planAction'
import { PlanType } from 'state/types/planType'
import { TreatsSchema } from 'utils/yup-validation-schema'
import BritishFlag from '../../../../images/BritishFlag.svg'
import BulletPointImage from '../../../../images/BulletPoint.svg'
import limitedEditionSticker from '../../../../images/limited-edition-sticker.svg'
import NewSticker from '../../../../images/new-sticker.svg'
import { getAccountSummary, getCustomerDetails, resetAccountSummary } from '../../../../state/actions/accountSummary'

const TreatAddonAccordion = ({ setNextPage, setRedZoneConfirmButton, setShowRedZoneModal }) => {
  const {
    summary
  }: {
    summary: AccountSummaryType
  } = useSelector((state: RootState) => state.accountSummary)
  const {
    plan,
    addonsSelected
  }: {
    plan: PlanType
    addonsSelected: any[]
  } = useSelector((state: RootState) => state.accountDogPlanFlow)
  const { dogInfo }: any = useSelector((state: RootState) => state.accountDogFlow)
  const { error, errorMessage } = useSelector((state: RootState) => state.accountDogShippingFlow)
  const [addons, setAddons] = useState<Array<{ id: string; quantity: number; price: number; name: string }>>([])
  const [addonCount, setAddonCount] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [modalActiveRecipe, setModalActiveRecipe] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState('')
  const [addonChange, setAddonChange] = useState(0)
  const [addonValue, setAddonValue] = useState(0)

  const [treatAddedToPlan, setTreatAddedToPlan] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [displayOneClickTreat, setDisplayOneClickTreat] = useState(false)
  const [editTreats, setEditTreats] = useState(false)
  const dispatch = useDispatch()

  // Featured treat Id
  const featuredTreatId = 'TRET-LODI'

  /**
 * Featured treat only shows for accounts with 1 plan

 * 1. Find out if there is a single plan, if true then show
 * 2. Completely populate the redux store once the single plan and subscriptionId is set
 * 3. Once treat has been added, continue to show with edit treat button
 
 */

  const activePlans = summary?.plans?.filter(p => p.status === 'active' || p.status === 'future')

  const featuredTreat = plan?.addons?.find((addon: any) => addon.itemId === featuredTreatId)

  useEffect(() => {
    setDisplayOneClickTreat(activePlans && activePlans.length === 1 ? true : false)
  }, [activePlans])

  useEffect(() => {
    if (displayOneClickTreat) {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'account_page_add_featured_treat_loaded', {
          page: 'account_page_add_featured_treat'
        })
      }
    }
  }, [displayOneClickTreat])

  useEffect(() => {
    if (featuredTreat && featuredTreat.quantity !== 0) {
      setTreatAddedToPlan(true)
    }
  }, [featuredTreat])

  useEffect(() => {
    // Get account summary to load redux
    const getAccountSummaryAndOrders = () => {
      dispatch(resetAccountSummary())
      dispatch(resetFlowDogs())
      dispatch(resetFlowPlan())
      dispatch(getAccountSummary())
      dispatch(getCustomerDetails())
    }
    getAccountSummaryAndOrders()
  }, [])

  useEffect(() => {
    // Reload redux when treats added
    const resetPlanFlowStore = () => {
      dispatch(resetAccountSummary())
      dispatch(removeAddons())
      dispatch(resetFlowPlan())
      setAddons([])
    }
    const getPlanFlowStore = (subscriptionId: string) => {
      dispatch(getAccountSummary())
      if (subscriptionId) {
        dispatch(
          createDogsExistingPlan({
            subscriptionId: subscriptionId,
            dogs: []
          })
        )
      }
    }
    if (subscriptionId || editTreats) {
      resetPlanFlowStore()
      setSubscriptionId(subscriptionId)
      getPlanFlowStore(subscriptionId)
    }
  }, [subscriptionId, editTreats])

  useEffect(() => {
    if (!subscriptionId) {
      if (activePlans?.length === 1) {
        setSubscriptionId(activePlans[0]?.subscriptionId)
      }
    }
  }, [activePlans, subscriptionId])

  useEffect(() => {
    // Load redux store fully
    if (summary && summary?.plans[0]?.weeks !== plan.weeks) {
      if (dogInfo?.defaultPackSize?.default) {
        const planType = dogInfo.defaultPackSize.default.toLowerCase().replace('_t', 'T')
        dispatch(
          populateRecipeAndTreatData({
            plan: dogInfo.defaultPackSize[planType],
            noOfPacks: dogInfo.defaultPackSize[planType].numberOfPacks,
            packSize: dogInfo.defaultPackSize[planType].packSize,
            weeks: dogInfo.defaultPackSize[planType].weeks,
            isDefaultPlan: dogInfo.defaultPackSize.default !== 'CUSTOM',
            packType: dogInfo.defaultPackSize.default,
            fixedOrCustomPlan: dogInfo.defaultPackSize.default === 'CUSTOM' ? 'CUSTOM' : 'FIXED'
          })
        )
      }
    }
  }, [dogInfo, subscriptionId])

  useEffect(() => {
    if (errorMessage || errorMessage?.popup) {
      setShowPopup(true)
    }
  }, [errorMessage])

  const disableAddonAddButton = (addonId: string, outOfStock: boolean) => {
    const index = addons.findIndex(addon => addon.id === addonId)
    const previousAddonsIndex = plan.addons.findIndex((addon: any) => addon.id === addonId)

    if (index !== -1) {
      const addonsArr = [...addons]
      if (outOfStock) {
        if (addonsArr[index].quantity >= plan.addons[previousAddonsIndex].quantity) {
          return true
        }
      }
    } else {
      if (outOfStock) {
        if (plan.addons[previousAddonsIndex].quantity > 0) {
          return false
        }
        return true
      }
    }
    return false
  }

  const handleAddAddon = (addonId: string, price: number, outOfStock: boolean, name: string) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      const previousAddonsIndex = plan.addons.findIndex((addon: any) => addon.id === addonId)

      if (index !== -1) {
        const addonsArr = [...addons]

        if (outOfStock) {
          if (addonsArr[index].quantity < plan.addons[previousAddonsIndex].quantity) {
            addonsArr[index].quantity = addonsArr[index].quantity + 1
            setAddons(addonsArr)
            setAddonCount(curr => curr + 1)
          }
        } else {
          addonsArr[index].quantity = addonsArr[index].quantity + 1
          setAddons(addonsArr)
          setAddonCount(curr => curr + 1)
        }
      } else {
        if (!outOfStock) {
          setAddons([...addons, { id: addonId, quantity: 1, price, name }])
          setAddonCount(curr => curr + 1)
        } else {
          if (plan.addons[previousAddonsIndex].quantity > 0) {
            setAddons([...addons, { id: addonId, quantity: 1, price, name }])
            setAddonCount(curr => curr + 1)
          }
        }
      }
    } else {
      if (!outOfStock) {
        setAddons([{ id: addonId, quantity: 1, price, name }])
        setAddonCount(curr => curr + 1)
      }
    }

    if (typeof window.gtag !== 'undefined' && plan?.addons?.length > 0) {
      const index = plan?.addons?.findIndex(addon => addon.id === addonId)
      const addonsIndex = addons?.findIndex(addon => addon.id === addonId)
      window.gtag('event', 'added_treat', {
        page: 'account_page_add_featured_treat',
        addon_id: plan.addons[index].id,
        addon_name: plan.addons[index].name,
        quantity: addonsIndex === -1 ? 1 : addons[addonsIndex].quantity
      })
    }
  }

  const handleRemoveAddon = (addonId: string) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      const plansAddonsIndex = plan?.addons?.findIndex(addon => addon.id === addonId)

      if (index !== -1) {
        if (addons[index].quantity > 1) {
          const addonsArr = [...addons]
          addonsArr[index].quantity = addonsArr[index].quantity - 1
          setAddons(addonsArr)
        } else {
          setAddons(addons.filter(addon => addon.id !== addonId))
        }
        setAddonCount(curr => curr - 1)

        if (typeof window.gtag !== 'undefined' && plan?.addons?.length > 0) {
          window.gtag('event', 'removed_treat', {
            page: 'account_page_add_featured_treat',
            addon_id: plan?.addons[plansAddonsIndex].id,
            addon_name: plan?.addons[plansAddonsIndex].name,
            quantity: addons[index].quantity - 1
          })
        }
      }
    }
  }

  const getAddonQuantity = (addonId: string) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      if (index !== -1) {
        return addons[index].quantity
      }
    }
    return 0
  }

  const getDiscountedAddonPrice = (price: number, treatId: string) => {
    const discountAppliedToAll = dogInfo?.defaultCoupon?.applicableIds?.length === 0 ? true : false

    let total = 0
    if (price > 0 && dogInfo.defaultCoupon?.code) {
      if (dogInfo.defaultCoupon.type === 'percentage') {
        if (discountAppliedToAll) {
          total = price - (dogInfo.defaultCoupon.amount / 100) * price
        } else {
          if (dogInfo?.defaultCoupon?.applicableIds.includes(treatId)) {
            total = price - (dogInfo.defaultCoupon.amount / 100) * price
          } else {
            total = price
          }
        }
      } else {
        total = price
      }
    }
    return total
  }

  useEffect(() => {
    if (addonsSelected?.length > 0) {
      let totalAddons = 0
      setAddons(addonsSelected)
      addonsSelected.forEach(addon => {
        totalAddons += addon.quantity
      })
      setAddonCount(totalAddons)
      // Treat count on accordion show
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'treat_count_begin', {
          page: 'account_page_add_featured_treat',
          quantity: totalAddons
        })
      }
      setAddonChange(totalAddons)
    } else {
      if (subscriptionId) {
        // Treat count on accordion show
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'treat_count_begin', {
            page: 'account_page_add_featured_treat',
            quantity: addonCount
          })
        }
        setAddonChange(addonCount)
      }
    }
  }, [addonsSelected])

  useEffect(() => {
    if (plan && addonsSelected?.length === 0) {
      const addonsSelectedLocal: any = []
      let totalAddons = 0
      plan.addons.map(addon => {
        if (addon.quantity > 0) {
          addonsSelectedLocal.push(addon)
          totalAddons += addon.quantity
        }
      })

      if (addonsSelectedLocal?.length > 0) {
        setAddonCount(totalAddons)
        dispatch(prefillAddons(addonsSelectedLocal))
        let addonTotal = 0
        addonsSelectedLocal.map(a => {
          addonTotal += a.quantity * a.price
        })
        setAddonValue(addonTotal)
      }
    }
  }, [plan])

  const getSelectedAddons = () => {
    const selectedAddons = addons.map(addon => {
      return { id: addon.id, quantity: addon.quantity }
    })
    return selectedAddons
  }

  const getRecipeHighlight = (highlight: any) => {
    // Create highlight from two highlights
    if (highlight.length > 1) {
      const highlightTwo = highlight[1].replace('100% British', '')
      return highlight[0] + ' and ' + highlightTwo
    }
    return highlight
  }

  const handleRedZoneButton = () => {
    setRedZoneConfirmButton(
      <button
        type="button"
        className="btn-cta inline-flex w-full justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
        onClick={() => {
          setShowRedZoneModal(false)
          submitTreats()
        }}
      >
        Edit treats
      </button>
    )
    setShowRedZoneModal(true)
  }

  const submitTreats = () => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'edit_treats', {
        page: 'account_page_add_featured_treat'
      })
    }
    setEditTreats(true)
    dispatch(resetChangedShipping())
    dispatch(
      createDogsExistingPlan({
        subscriptionId: subscriptionId,
        dogs: []
      })
    )
    setNextPage('treats')
    dispatch(updatePlanRadio(subscriptionId))
  }

  return (
    <>
      {!featuredTreat && !displayOneClickTreat && <></>}
      {featuredTreat && displayOneClickTreat && (
        <>
          <div>
            <Formik
              initialValues={{ treats: [] }}
              validationSchema={TreatsSchema}
              onSubmit={async () => {
                setSubmitting(true)
                const selectedAddons = getSelectedAddons()
                const dataToForward: any = {
                  addons: selectedAddons,
                  subscriptionId: subscriptionId,
                  source: 'account'
                }
                await dispatch(addAddons(selectedAddons))
                await dispatch(createShippingConfirmDogsExistingAddons(dataToForward))
                // Treat count on submit
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'account_page_add_featured_treat_submit', {
                    page: 'account_page_add_featured_treat',
                    quantity: addonCount
                  })
                }

                let addonTotal = 0
                addons.map(a => {
                  addonTotal += a.quantity * a.price
                })
                // Treat difference
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'account_page_add_featured_treat_count_difference', {
                    page: 'account_page_add_featured_treat',
                    difference: addonCount - addonChange,
                    value: addonTotal - addonValue
                  })
                }
                if (!errorMessage) {
                  setTreatAddedToPlan(true)
                  dispatch(resetChangedShipping())
                  dispatch(
                    createDogsExistingPlan({
                      subscriptionId: subscriptionId,
                      dogs: []
                    })
                  )
                }
                setSubmitting(false)
              }}
              validateOnMount
            >
              {props => {
                const outOfStock = featuredTreat?.status === 'OUTOFSTOCK'

                return (
                  <div className="relative">
                    <div className="container mx-auto md:mx-auto my-10">
                      {activePlans?.length && (
                        <>
                          <div className="flex justify-center rounded-3xl p-2 text-center">
                            <h1
                              data-testid="ourRecommendationAccount"
                              className=" font-sans font-bold inline-block text-lg lg:text-2xl"
                            >
                              We think {activePlans[0]?.dogNames} will love this
                            </h1>
                          </div>
                          <div
                            className={`my-2 mx-auto flex ${treatAddedToPlan ? 'h-auto' : 'h-auto max-h-[35rem] xs:h-[33rem]'} w-full flex-wrap bg-checkoutBG md:h-[27rem] lg:h-72 lg:min-w-[900px] lg:flex-nowrap xl:h-64 rounded-3xl lg:mx-auto lg:w-full`}
                          >
                            <div className="relative flex h-1/2 w-1/2 pl-4 pt-4 md:h-[64%] md:pl-8 lg:h-full lg:w-full lg:max-w-[256px] lg:pl-0 lg:pt-0 rounded-3xl overflow-hidden">
                              <CdnImage
                                url={featuredTreat?.images?.secondary}
                                alt={featuredTreat?.name}
                                steps={[400]}
                                className="lg:w[100%] rounded-3xl lg:rounded-none lg:rounded-l-3xl object-top md:h-[86%] md:w-[90%] block lg:h-[100%] lg:object-cover"
                              />
                              {featuredTreat?.limitedEdition && !featuredTreat?.isNew && (
                                <img
                                  style={{ lineHeight: '1rem', right: '0.5rem' }}
                                  className="index-1 absolute -top-4 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                  src={limitedEditionSticker}
                                  alt="Limited Edition"
                                />
                              )}
                              {featuredTreat?.isNew && (
                                <span
                                  style={{ lineHeight: '1rem', right: '0.5rem' }}
                                  className="index-1 absolute -top-4 left-4 md:-top-0 lg:left-0 inline-flex h-24 w-24 -rotate-12 md:h-20 md:w-20 lg:top-0 lg:h-20 lg:w-20 xl:inline-flex"
                                >
                                  <img
                                    className="w-1/2 max-w-[75px] -rotate-12 object-contain md:w-full"
                                    src={NewSticker}
                                    alt="New"
                                  />
                                </span>
                              )}
                            </div>

                            <div className="flex h-1/3 w-1/2 justify-between p-4 pb-0 md:p-4 md:pb-0 lg:w-full lg:px-6 lg:pb-4 xl:p-6">
                              <div className="ml-0 w-full md:-ml-2 lg:-ml-6">
                                <div className="mb-4 flex items-center">
                                  <h4 className="font-sans font-semibold text-xl text-primary">
                                    {featuredTreat?.name}
                                  </h4>
                                </div>

                                <p className="mb-4 flex w-full items-center text-left text-xs text-black md:text-sm">
                                  {featuredTreat?.shortDescription ?? ''}
                                </p>

                                <div className="mb-0">
                                  {featuredTreat?.bullets && featuredTreat?.bullets?.length > 0 && (
                                    <ul className="mt-4 flex flex-col md:flex-row w-full items-start md:items-center justify-start gap-y-4 md:gap-x-4">
                                      <li
                                        key={'bullet'}
                                        className="lg:mx-1 flex items-center justify-start md:justify-center md:flex-initial"
                                      >
                                        <img
                                          width={20}
                                          src={BulletPointImage}
                                          alt={featuredTreat?.bullets[0]}
                                          className="inline-block"
                                        />
                                        <span className="ml-2 text-xs md:text-sm inline-block">
                                          {featuredTreat?.bullets[0]}
                                        </span>
                                      </li>
                                      {featuredTreat?.highlights && featuredTreat?.highlights.length > 0 && (
                                        <li
                                          key={'highlight'}
                                          className="mx-1 flex items-center justify-start md:justify-center md:flex-initial ml-0 md:ml-4"
                                        >
                                          <img
                                            width={20}
                                            src={BritishFlag}
                                            alt={'British Flag'}
                                            className="inline-block"
                                          />
                                          <span className="ml-2 text-xs md:text-sm inline-block">
                                            {getRecipeHighlight(featuredTreat?.highlights)}
                                          </span>
                                        </li>
                                      )}
                                    </ul>
                                  )}
                                </div>

                                <button
                                  className="btn-secondary my-4 text-sm !hidden md:!block"
                                  onClick={() => {
                                    setModalShow(true)
                                    setModalActiveRecipe(featuredTreat)
                                  }}
                                >
                                  Additional information
                                </button>
                              </div>
                            </div>
                            {!treatAddedToPlan && featuredTreat && (
                              <div
                                className={`mt-12 ml-0 hidden h-1/2 w-1/2 flex-col items-center gap-y-4 text-left md:-mt-4 md:-ml-2 md:flex lg:mr-8 lg:mt-12 lg:ml-0 lg:w-1/3`}
                              >
                                {(featuredTreat?.price / 100)?.toFixed(2) !== 'NaN' && (
                                  <p className={`text-xl font-bold lg:text-2xl`}>
                                    {getDiscountedAddonPrice(featuredTreat?.price, featuredTreat?.id) > 0 &&
                                      getDiscountedAddonPrice(featuredTreat?.price, featuredTreat?.id) !==
                                        featuredTreat?.price && (
                                        <span className={`strikethrough mr-2 text-[#5C5C5C]`}>
                                          £{(featuredTreat?.price / 100)?.toFixed(2)}
                                        </span>
                                      )}
                                    <span
                                      className={`${
                                        getDiscountedAddonPrice(featuredTreat?.price, featuredTreat?.id) > 0
                                          ? 'ml-2'
                                          : ''
                                      } text-primary`}
                                    >
                                      {getDiscountedAddonPrice(featuredTreat?.price, featuredTreat?.id) > 0
                                        ? `£${getDiscountedAddonPrice(
                                            featuredTreat?.price / 100,
                                            featuredTreat?.id
                                          )?.toFixed(2)}`
                                        : `£${(featuredTreat?.price / 100)?.toFixed(2)}`}
                                    </span>
                                  </p>
                                )}

                                <div className="flex w-[200px] flex-col items-center gap-y-4">
                                  {activePlans?.length === 1 && getAddonQuantity(featuredTreat?.id) >= 1 && (
                                    <div>
                                      <div className="flex items-center justify-center">
                                        <button
                                          className="btn-cta mr-4 h-10 w-10 !pt-[0.35rem]"
                                          type="button"
                                          onClick={() => handleRemoveAddon(featuredTreat?.id)}
                                        >
                                          -
                                        </button>
                                        <Field
                                          name="treats"
                                          type="text"
                                          placeholder="0"
                                          className="w-1/3 rounded-3xl border-[#D1D5DA] bg-white py-3 px-4 text-center"
                                          value={getAddonQuantity(featuredTreat?.id)}
                                          inputMode="none"
                                          onFocus={(e: any) => e.target.blur()}
                                        />
                                        <button
                                          className="btn-cta ml-4 h-10 w-10 !pt-[0.35rem] disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                          type="button"
                                          disabled={disableAddonAddButton(featuredTreat?.id, outOfStock)}
                                          onClick={() =>
                                            handleAddAddon(
                                              featuredTreat?.id,
                                              featuredTreat?.price,
                                              outOfStock,
                                              featuredTreat?.name
                                            )
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  <div className="relative min-h-[50px] w-full flex justify-center mx-auto flex-col">
                                    <button
                                      className="btn-cta !px-8 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                      disabled={disableAddonAddButton(featuredTreat?.id, outOfStock)}
                                      onClick={() => {
                                        if (getAddonQuantity(featuredTreat?.id) === 0) {
                                          handleAddAddon(
                                            featuredTreat?.id,
                                            featuredTreat?.price,
                                            outOfStock,
                                            featuredTreat?.name
                                          )
                                        } else {
                                          props.handleSubmit()
                                        }
                                      }}
                                    >
                                      {submitting ? (
                                        <div className="align-center flex justify-center">
                                          <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                                          <div>Saving</div>
                                        </div>
                                      ) : getAddonQuantity(featuredTreat?.id) === 0 ? (
                                        'Add to plan'
                                      ) : (
                                        'Confirm'
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {treatAddedToPlan && !errorMessage && (
                              <div className="flex flex-col w-1/2">
                                <div className="mt-12 ml-0 hidden h-1/2 w-1/2 flex-row items-center gap-y-4 text-left md:-ml-2 md:flex md:mt-4 lg:mt-20 md:h-auto md:w-full justify-center mx-auto">
                                  <span className="font-sans font-bold text-base">Added to plan</span>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ color: '#006685' }}
                                    className="ml-2 block h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="mt-12 ml-0 hidden h-1/2 w-1/2 flex-row items-center gap-y-4 text-left md:-ml-2 md:flex md:mt-4 md:h-auto md:w-full justify-center mx-auto">
                                  <button
                                    data-testid="editTreats"
                                    className="my-5 mr-4 w-full rounded-full border-2 border-primary py-2 px-4 hover:underline focus:underline md:my-0 md:w-auto"
                                    onClick={() => {
                                      if (activePlans.redZone) {
                                        handleRedZoneButton()
                                      } else {
                                        submitTreats()
                                      }
                                    }}
                                  >
                                    Edit treats
                                  </button>
                                </div>
                              </div>
                            )}

                            <div className="flex justify-center items-center w-full mx-auto md:hidden">
                              <div className="items-center justify-between gap-y-4 pt-0">
                                {!treatAddedToPlan && featuredTreat && (
                                  <>
                                    {(featuredTreat?.price / 100)?.toFixed(2) !== 'NaN' && (
                                      <p className={`mb-2 ml-4 text-xl font-bold lg:text-2xl`}>
                                        {getDiscountedAddonPrice(featuredTreat?.price, featuredTreat?.id) > 0 &&
                                          getDiscountedAddonPrice(featuredTreat?.price, featuredTreat?.id) !==
                                            featuredTreat?.price && (
                                            <span className={`strikethrough mr-2 text-[#5C5C5C]`}>
                                              £{(featuredTreat?.price / 100)?.toFixed(2)}
                                            </span>
                                          )}
                                        <span
                                          className={`${
                                            getDiscountedAddonPrice(featuredTreat?.price, featuredTreat?.id) > 0
                                              ? 'ml-2'
                                              : 'ml-10 lg:ml-0'
                                          } text-primary`}
                                        >
                                          {getDiscountedAddonPrice(featuredTreat?.price, featuredTreat?.id) > 0
                                            ? `£${getDiscountedAddonPrice(
                                                featuredTreat?.price / 100,
                                                featuredTreat?.id
                                              )?.toFixed(2)}`
                                            : `£${(featuredTreat?.price / 100)?.toFixed(2)}`}
                                        </span>
                                      </p>
                                    )}
                                    <div className="flex w-[170px] flex-col items-center gap-y-4">
                                      {activePlans?.length === 1 && getAddonQuantity(featuredTreat?.id) >= 1 && (
                                        <div>
                                          <div className="flex items-center justify-center">
                                            <button
                                              className="btn-cta mr-4 h-10 w-10 !pt-[0.35rem]"
                                              type="button"
                                              onClick={() => handleRemoveAddon(featuredTreat?.id)}
                                            >
                                              -
                                            </button>

                                            <Field
                                              name="treats"
                                              type="text"
                                              placeholder="0"
                                              className="w-1/3 rounded-3xl border-[#D1D5DA] bg-white py-3 px-4 text-center"
                                              value={getAddonQuantity(featuredTreat?.id)}
                                              inputMode="none"
                                              onFocus={(e: any) => e.target.blur()}
                                            />

                                            <button
                                              className="btn-cta ml-4 h-10 w-10 !pt-[0.35rem] disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                              type="button"
                                              disabled={disableAddonAddButton(featuredTreat?.id, outOfStock)}
                                              onClick={() =>
                                                handleAddAddon(
                                                  featuredTreat?.id,
                                                  featuredTreat?.price,
                                                  outOfStock,
                                                  featuredTreat?.name
                                                )
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </div>
                                      )}

                                      <div className="min-h-[50px] relative">
                                        <button
                                          className="btn-cta !px-4 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                          disabled={disableAddonAddButton(featuredTreat?.id, outOfStock)}
                                          onClick={() => {
                                            if (getAddonQuantity(featuredTreat?.id) === 0) {
                                              handleAddAddon(
                                                featuredTreat?.id,
                                                featuredTreat?.price,
                                                outOfStock,
                                                featuredTreat?.name
                                              )
                                            } else {
                                              props.handleSubmit()
                                            }
                                          }}
                                        >
                                          {submitting ? (
                                            <div className="align-center flex justify-center">
                                              <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                                              <div>Saving</div>
                                            </div>
                                          ) : getAddonQuantity(featuredTreat?.id) === 0 ? (
                                            'Add to plan'
                                          ) : (
                                            'Confirm'
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {treatAddedToPlan && (
                                  <div className="flex justify-center items-center w-full mx-auto md:hidden flex-col">
                                    <div className="flex flex-row mt-8 mb-2">
                                      <span className="font-sans font-bold text-base">Added to plan</span>
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: '#006685' }}
                                        className="ml-2 block h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className=" ml-0 h-auto w-full flex-row items-center gap-y-4 text-left md:-ml-2 md:flex justify-center mx-auto">
                                      <button
                                        data-testid="editTreats"
                                        className="my-2 mr-4 w-full rounded-full border-2 border-primary py-2 px-4 hover:underline focus:underline md:my-0 md:w-auto"
                                        onClick={() => {
                                          if (activePlans.redZone) {
                                            handleRedZoneButton()
                                          } else {
                                            submitTreats()
                                          }
                                        }}
                                      >
                                        Edit treats
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <div className={`${treatAddedToPlan ? 'mt-2' : 'mt-0'} flex justify-center mx-auto`}>
                                  <button
                                    className="btn-secondary mx-auto text-xs mt-4 mb-8 sm:text-sm"
                                    onClick={() => {
                                      setModalShow(true)
                                      setModalActiveRecipe(featuredTreat)
                                    }}
                                  >
                                    Additional information
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )
              }}
            </Formik>
          </div>
          <RecipeOrTreatAlterModal show={modalShow} setShow={setModalShow} activeRecipe={modalActiveRecipe} />

          {errorMessage && (
            <ErrorModal
              show={showPopup}
              setShow={setShowPopup}
              errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
              url={errorMessage?.popup?.url ?? errorMessage?.url}
              action={errorMessage?.popup?.action ?? errorMessage?.action}
              actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
            />
          )}
        </>
      )}{' '}
    </>
  )
}

export default TreatAddonAccordion
